import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelComponent } from './label.component';
import { TranslateModule } from '@ngx-translate/core';
import { SafeModule } from '@app/shared/pipes';

@NgModule({
  declarations: [LabelComponent],
  imports: [CommonModule, TranslateModule, SafeModule],
  exports: [LabelComponent],
})
export class LabelModule {}
