import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LayoutDirectionModule } from '@app/shared/directives';
import { SwiperModule } from 'swiper/angular';
import { SayyalAdvertisingComponent } from './sayyal-advertising.component';

@NgModule({
  declarations: [SayyalAdvertisingComponent],
  imports: [CommonModule, LayoutDirectionModule, SwiperModule],
  exports: [SayyalAdvertisingComponent],
})
export class SayyalAdvertisingModule {}
