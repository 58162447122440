import { GroupUnit } from './group-unit';
import { MarketUnit } from './market-unit';
import { MegaProject } from './mega-project';
import { Project } from './project';
import { UnitModel } from './unit-model';
import { MarketingRentalUnit } from './marketing-rental-unit';
import { MarketplaceUnitModel } from './marketplace-unit-model';
import { QueryMegaProject } from './query-mega-project';
import { Marketplace } from './marketplace';
import { Resource } from '@app/enums';

export interface MarketplaceDataInterface {
  attributes?: Marketplace;
  id?: string;
  relationships?: any;
  type?: string;
}

export class FavoriteProject {
  relationships = {
    group_unit: { rel: 'has_one', model: GroupUnit },
    market_unit: { rel: 'has_one', model: MarketUnit },
    marketing_rental_unit: { rel: 'has_one', model: MarketingRentalUnit },
    mega_project: { rel: 'has_one', model: MegaProject },
    project: { rel: 'has_one', model: Project },
    unit: { rel: 'has_one', model: UnitModel },
    unit_model: { rel: 'has_one', model: MarketplaceUnitModel },
  };
  constructor(
    public resource_id?: number,
    public resource_type?: string,
    public subscription_type?: string,
    public beneficiary_national_id_number?: string,
    public extra_info?: { is_land: boolean },
    public project?: Project,
    public mega_project?: QueryMegaProject,
    public unit?: MarketplaceUnitModel,
    public unit_model?: MarketplaceUnitModel,
    public market_unit?: MarketUnit,
    public group_unit?: GroupUnit,
    public marketing_rental_unit?: MarketingRentalUnit
  ) {}

  toMarketplace(item: FavoriteProject, type: Resource): Marketplace {
    let marketplace: Marketplace;

    switch (type) {
      case Resource.PROJECTS:
        let project = item.project;
        marketplace = Object.assign(new Marketplace(), this.toProject());
        marketplace.min_price = project?.price_starting_at;
        marketplace.starting_bid_price = project?.price_starting_at;
        marketplace.marketplace_price = project?.price_starting_at;
        marketplace.resource_id = String(item.resource_id);
        marketplace.resource_type = item.resource_type;
        marketplace.project_name = project?.media_name;
        marketplace.name = project?.name;
        marketplace.city = project?.city_obj;
        marketplace.region = project?.region_obj;
        marketplace.nhc_related = project?.is_nhc_project;
        marketplace.extra_info = item.extra_info;
        marketplace.last_published_media = project?.media;
        break;
      case Resource.MEGA_PROJECTS:
        let mega_project = item.mega_project;
        marketplace = Object.assign(new Marketplace(), this.toMegaProject());
        marketplace.resource_id = String(item.resource_id);
        marketplace.resource_type = item.resource_type;
        marketplace.project_id = +mega_project?.id!;
        marketplace.content = mega_project?.content;
        marketplace.cover_image = mega_project?.data?.attributes?.banner_image?.url;
        marketplace.title = mega_project?.title;
        marketplace.city = mega_project?.data?.attributes?.city_obj;
        marketplace.region = mega_project?.data?.attributes?.region_obj;
        marketplace.available_units_count = mega_project?.data?.attributes?.apartment_count;
        marketplace.area = mega_project?.total_area;
        break;
      case Resource.UNITS:
        let unit = item.unit;
        marketplace = Object.assign(new Marketplace(), this.toUnit());
        marketplace.nhc_related = item.project?.is_nhc_project;
        marketplace.extra_info = item.extra_info;
        marketplace.city = unit?.data?.attributes?.city;
        marketplace.region = unit?.data?.attributes?.region;
        marketplace.id = +unit?.data?.id!;
        marketplace.type = unit?.data?.type;
        marketplace.project_name = item.project?.name;
        marketplace.can_book = unit?.data?.attributes?.can_book;
        marketplace.project = item.project;
        marketplace.last_published_media = item.unit_model?.last_published_media;
        break;
      case Resource.UNIT_MODELS:
        let unitModel = item.unit_model;
        marketplace = Object.assign(new Marketplace(), this.toUnitModel());
        marketplace.resource_id = String(item.resource_id);
        marketplace.resource_type = item.resource_type;
        marketplace.project_id = item?.project?.id;
        marketplace.project_type = item?.project?.project_type;
        marketplace.project_name = item?.project?.media_name;
        marketplace.extra_info = item.extra_info;
        marketplace.last_published_media = unitModel?.last_published_media;
        marketplace.nhc_related = item?.project?.is_nhc_project;
        marketplace.city = item?.project?.city_obj;
        marketplace.region = item?.project?.region_obj;
        marketplace.model = unitModel?.data?.attributes?.model;
        marketplace.bookable = item?.project?.bookable;
        break;
      case Resource.MARKET_UNITS:
        let marketUnit = item.market_unit;
        marketplace = Object.assign(new Marketplace(), this.toMarketUnit());
        marketplace.resource_id = String(item.resource_id);
        marketplace.resource_type = item.resource_type;
        marketplace.location = marketUnit?.location;
        marketplace.unit_type = marketUnit?.unit_type;
        marketplace.city_id = marketUnit?.city_id?.toString();
        marketplace.region_id = marketUnit?.region_id?.toString();
        marketplace.city = marketUnit?.data?.attributes?.city;
        marketplace.region = marketUnit?.data?.attributes?.region;
        marketplace.min_area = marketUnit?.unit_area;
        marketplace.min_price = marketUnit?.unit_price;
        marketplace.min_bed_room = marketUnit?.number_of_rooms;
        marketplace.min_bathroom = marketUnit?.number_of_bathrooms;
        marketplace.extra_info = item.extra_info;
        break;
      case Resource.GROUP_UNITS:
        marketplace = Object.assign(new Marketplace(), this.toGroupUnit());
        marketplace.extra_info = item.extra_info;
        break;
      case Resource.MARKETING_RENTAL_UNITS:
        let rentalUnit = item.marketing_rental_unit;
        marketplace = Object.assign(new Marketplace(), this.toRentalUnit());
        marketplace.resource_id = String(item.resource_id);
        marketplace.resource_type = item.resource_type;
        marketplace.city = rentalUnit?.city_obj;
        marketplace.region = rentalUnit?.region_obj;
        break;
      default:
        throw new Error(`Unsupported type: ${type}`);
    }

    return marketplace;
  }

  toProject(): Project {
    return {
      ...this.project,
      resource_id: this.resource_id,
      resource_type: this.resource_type,
      is_favorite: true,
    } as Project;
  }

  toMegaProject(): QueryMegaProject {
    return {
      ...this.mega_project,
      resource_id: this.resource_id,
      resource_type: this.resource_type,
      is_favorite: true,
    } as QueryMegaProject;
  }

  toUnit(): MarketplaceUnitModel {
    return {
      ...this.unit,
      resource_id: this.resource_id,
      resource_type: this.resource_type,
      is_favorite: true,
    } as MarketplaceUnitModel;
  }

  toUnitModel(): MarketplaceUnitModel {
    return {
      ...this.unit_model,
      resource_id: this.resource_id,
      resource_type: this.resource_type,
      is_favorite: true,
    } as MarketplaceUnitModel;
  }

  toMarketUnit(): MarketUnit {
    return {
      ...this.market_unit,
      resource_id: this.resource_id,
      resource_type: this.resource_type,
      is_favorite: true,
    } as MarketUnit;
  }

  toGroupUnit(): GroupUnit {
    return {
      ...this.group_unit,
      resource_id: this.resource_id,
      resource_type: this.resource_type,
      is_favorite: true,
    } as GroupUnit;
  }

  toRentalUnit(): MarketingRentalUnit {
    return {
      ...this.marketing_rental_unit,
      resource_id: this.resource_id,
      resource_type: this.resource_type,
      is_favorite: true,
    } as MarketingRentalUnit;
  }
}

export class FavoriteProjectResponse {
  constructor(public data: FavoriteProject[], public included?: Array<any>) {}
}

export class FavoriteProjectLocalStorage {
  constructor(public resource_type?: string, public favorites?: (number | undefined)[]) {}
}
