export enum BlockBookingReason {
  ALREADY_HAS_ACTIVE_BOOKING = 'already_has_active_booking',
  IS_NOT_ELIGIBLE = 'is_not_eligible',
  IS_ALREADY_ALLOCATED = 'is_already_allocated',
  HAS_FINANCIAL_APPLICATION = 'has_financial_application',
  EXIST_IN_MOHO_LIST = 'exist_in_moho_list',
  HAS_TANMAWI_CONTRACT = 'has_tanmawi_contract',
  BOOKING_TOKEN_EXPIRED = 'booking_token_expired',
  BOOKING_TOKEN_NOT_FOUND = 'booking_token_not_found',
  PROJECT_DOES_NOT_MATCH_THE_TOKEN = 'project_does_not_match_the_token',
  INVALID_AVAILABLE_UNIT = 'invalid_available_unit',
  ALREADY_HAS_RESERVED_UNIT = 'already_has_reserved_unit',
  SERVICE_UNAVAILABLE = 'service_unavailable',
  UNIT_IS_NOT_AVAILABLE = 'unit_is_not_available',
  DUPLICATED_BOOKING_TOKEN = 'duplicated_booking_token',
}
