export enum AdvertisingZoneAlias {
  // Marketplace page
  DESKTOP_MARKETPLACE_PAGE_TOP_SECTION = 'DesktopMarketplacePageTopSection',
  MOBILE_MARKETPLACE_PAGE_TOP_SECTION = 'MobileMarketplacePageTopSection',

  // Voucher page
  DESKTOP_VOUCHER_PAGE_BOTTOM_SECTION = 'DesktopVoucherPageBottomSection',
  MOBILE_VOUCHER_PAGE_BOTTOM_SECTION = 'MobileVoucherPageBottomSection',

  // Engineering Supervision Offices page
  DESKTOP_ENGINEERING_SUPERVISION_OFFICES_PAGE_MIDDLE_SECTION = 'DesktopEngineeringSupervisionOfficesPageMiddleSection',
  MOBILE_ENGINEERING_SUPERVISION_OFFICES_PAGE_MIDDLE_SECTION = 'MobileEngineeringSupervisionOfficesPageMiddleSection',
}
