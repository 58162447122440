export class Developer {
  relationships = {};
  constructor(
    public realname?: string,
    public last_published_media?: any,
    public id?: string,
    public address?: string
  ) {}

  get logoObject(): any {
    return this.last_published_media?.logo?.attributes!;
  }
}
