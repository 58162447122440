export const environment = {
  production: true,
  recaptchaSiteKey: '6LcponUUAAAAAP-4-9-jgBXo23HKOWc4rQEpHRtn',
  recaptchaV3SiteKey: '6LchigkpAAAAAJRfq8dJWPyBEXZ6YMBxU-3sqra9',
  appMode: 'development',
  lokaliseS3Path: {
    // en: 'https://ruh-s3.bluvalt.com/i18n/locales/en_sakani_v4_fe.json',
    // ar: 'https://ruh-s3.bluvalt.com/i18n/locales/ar_sakani_v4_fe.json',
    en: 'https://jed-s3.bluvalt.com/sakani-uploaded-files-public/locales/en_sakani_v4_fe.json',
    ar: 'https://jed-s3.bluvalt.com/sakani-uploaded-files-public/locales/ar_sakani_v4_fe.json',
  },
  lokaliseCExpiredInMins: 1,
  cookieDomain: 'housingapps.sa',
  cookieSecure: true,
  cmsSiteId: 'sakani-test',
  API_URL: '',
  authPath: 'https://test-sakani.housingapps.sa/auth',
  azmPaymentCallback: 'https://test-sakani.housingapps.sa/app/booking/offplan/azm-payment-callback',
  azmPaymentExtendPriceQuotationCallback:
    'https://test-sakani.housingapps.sa/app/extend-price-quotation/azm-payment-callback',
  firebaseDebugMode: true,
  firebaseConfig: {
    apiKey: 'AIzaSyBczIY5rrlLBziNCCGp1GUtFO8VsMruShI',
    authDomain: 'sakani-test1-app.firebaseapp.com',
    projectId: 'sakani-test1-app',
    storageBucket: 'sakani-test1-app.appspot.com',
    messagingSenderId: '666260203573',
    appId: '1:666260203573:web:da55dd4d1d0d3d1da3697c',
    measurementId: 'G-B96927Y7LE',
  },
  availableOnMobileLink: '/available-on-mobile',
  azmCallback: 'https://test-sakani.housingapps.sa/app/payment-callback',
  azmPaygate: 'https://checkout.housingapps.sa',
  kioskPaygate: 'https://checkout.ejar.sa/TestNearPay/index.html',
  kioskCallback: 'https://test1-sakani-v3.sakani.housing.sa/app/cityscape',
  esriApiKey: 'AAPKdf709649ac2c45f4830c6bd0ab0ad137niclppc9164ctWHgk2XNyB-0pLJi83pazviwfGY_9zLKgxW5G-jSX35-KjJjeQRW',
  nhcPath: 'https://test-nhc-sakani.housingapps.sa',
  sakaniPath: 'https://test-sakani.housingapps.sa/app',
  sakaniCmsPath: 'https://test-sakani.housingapps.sa',
  donationLink: '/jood_donations',
  campaignMode: false,
  fspUrl: 'https://test.azmscfp.com:444/FSPSakani/',
  insightsPage: 'https://insights.sakani.sa/',
  sakaniPartnerPath: 'https://test-partners-sakani.housingapps.sa/landing',
};
