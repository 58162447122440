import { environment } from '@environment/environment';

export class Endpoints {
  // CMS paths
  public static readonly CMS_API_PATH =
    environment.API_URL + `/cms_portal/api/cms_sitemap_meta/${environment.cmsSiteId}`;

  public static readonly CMS_FINANCIAL_ADVISORY_PATH =
    environment.API_URL + `/cms_portal/${environment.cmsSiteId}/preview/services/financial-advisory`;

  // CMS Featured sections for Design profile, Interior design
  public static readonly CMS_FEATURED_SECTIONS_API_PATH =
    environment.API_URL + `/cms_portal/api/featured_sections/${environment.cmsSiteId}`;

  // CMS sections for certified contractors landing page
  public static readonly CMS_CERTIFIED_CONTRACTORS_API_PATH =
    environment.API_URL + `/cms_portal/api/certified_contractors/${environment.cmsSiteId}`;

  // CMS voucher sections path
  public static readonly CMS_VOUCHER_SECTIONS_API_PATH_V1 =
    environment.API_URL + `/cms_portal/api/v1/voucher_sections/${environment.cmsSiteId}`;

  // CMS marketplace day banner path
  public static readonly CMS_MARKETPLACE_DAY_BANNER_API_PATH_V1 =
    environment.API_URL + `/cms_portal/api/v1/marketplace_day_banner/${environment.cmsSiteId}`;

  // CMS marketplace day informative path
  public static readonly CMS_MARKETPLACE_DAY_INFORMATIVES_API_PATH_V1 =
    environment.API_URL + `/cms_portal/api/v1/marketplace_day_informatives/${environment.cmsSiteId}`;

  public static readonly CMS_BASE_PATH = environment.API_URL + '/cms_portal/api';

  // CMS Popup
  public static readonly CMS_POPUP_API_PATH_V1 =
    environment.API_URL + `/cms_portal/api/v1/app_modal_data/${environment.cmsSiteId}`;

  //Marketing Rental Unit
  public static readonly MARKETING_RENTAL_UNIT_PATH =
    environment.API_URL + '/marketUnitsApi/v4/beneficiary/marketing_rental_units';

  // sakani-marketplace-service paths
  public static readonly MARKETPLACE_PROJECTS_PATH = environment.API_URL + '/marketplaceApi/search/v1/projects';

  public static readonly MARKETPLACE_SERVICE_SEARCH_PATH = environment.API_URL + '/marketplaceApi/search';

  public static readonly MARKETPLACE_SERVICE_PATH = environment.API_URL + '/marketplaceApi';

  public static readonly MARKETPLACE_LOCATION_PATH = environment.API_URL + '/marketplaceApi/search/v1/location';

  public static readonly QUERIES_MARKETPLACE_PATH = environment.API_URL + '/marketplaceApi/search/v1/marketplace';

  public static readonly CITIES_PATH = environment.API_URL + '/marketplaceApi/search/v1/cities';

  public static readonly DISTRICTS_PATH = environment.API_URL + '/marketplaceApi/search/v1/districts';

  public static readonly REGIONS_PATH = environment.API_URL + '/marketplaceApi/search/v1/regions';

  public static readonly KSA_CITIES_VERSION_PATH = environment.API_URL + '/marketplaceApi/integration/ksa_version';

  public static readonly MARKETPLACE_LOCATION_PATH_V2 = environment.API_URL + '/marketplaceApi/search/v2/location';

  public static readonly MARKETPLACE_LOCATION_PATH_V3 = environment.API_URL + '/marketplaceApi/search/v3/location';

  public static readonly MARKETPLACE_LOCATION_STATISTICS_PATH_V2 =
    environment.API_URL + '/marketplaceApi/search/v2/location/region-statistics';

  // sakani-queries-service paths
  public static readonly QUERRY_POLLING_PATH = environment.API_URL + '/sakani-queries-service/cqrs-res';

  // Tenancy service payhs
  public static readonly TENANCIES_API_V1_URL = environment.API_URL + '/tenanciesApi/v1';

  // mainIntermediary paths
  public static readonly PROJECTS_PATH = environment.API_URL + '/mainIntermediaryApi/v4/projects';

  public static readonly MAIN_INTERMEDIARY_V2 = environment.API_URL + '/mainIntermediaryApi/v2';

  public static readonly MAIN_INTERMEDIARY_V3 = environment.API_URL + '/mainIntermediaryApi/v3';

  public static readonly MAIN_INTERMEDIARY_V4 = environment.API_URL + '/mainIntermediaryApi/v4';

  public static readonly MAIN_INTERMEDIARY_V5 = environment.API_URL + '/mainIntermediaryApi/v5';

  public static readonly MAIN_INTERMEDIARY_API_V2 = environment.API_URL + '/mainIntermediaryApi/v2';

  public static readonly DIRECT_UPLOAD_URL_MAIN_INTERMEDIARY =
    environment.API_URL + '/mainIntermediaryApi/direct_uploads';

  // marketUnits paths
  public static readonly MARKET_UNIT_API = environment.API_URL + '/marketUnitsApi/v4';

  public static readonly MARKET_UNIT_API_V5 = environment.API_URL + '/marketUnitsApi/v5';

  public static readonly MARKET_UNIT_API_V6 = environment.API_URL + '/marketUnitsApi/v6';

  public static readonly MARKET_UNIT_REPORT_PATH = environment.API_URL + '/marketUnitsApi/v4/unit_reports';

  public static readonly APPROVED_MARKET_UNITS_PATH =
    environment.API_URL + '/marketUnitsApi/v4/market_units/approved_units';

  public static readonly APPROVED_GROUP_UNITS_PATH =
    environment.API_URL + '/marketUnitsApi/v4/group_units/approved_group_units';

  public static readonly MARKET_UNIT_REQUESTS_PATH =
    environment.API_URL + '/marketUnitsApi/v6/beneficiary/base_requests';

  public static readonly BENEFICIARY_GROUP_UNITS_PATH_V6 =
    environment.API_URL + '/marketUnitsApi/v6/beneficiary/group_units';

  public static readonly BENEFICIARY_MARKET_UNITS_LIST_PATH_V6 =
    environment.API_URL + '/marketUnitsApi/v6/beneficiary/market_units';

  public static readonly BENEFICIARY_MARKET_UNIT_PATH_V6 =
    environment.API_URL + '/marketUnitsApi/v6/beneficiary/market_units';

  public static readonly OWNER_MARKET_UNITS_PATH = environment.API_URL + '/marketUnitsApi/v5/owner/market_units';

  public static readonly OWNER_LAND_UNITS_PATH = environment.API_URL + '/marketUnitsApi/v5/owner/land_units';

  public static readonly OWNER_GROUP_UNITS_PATH = environment.API_URL + '/marketUnitsApi/v5/owner/group_units';

  public static readonly AVAILABLE_DEEDS_PATH = environment.API_URL + '/marketUnitsApi/v5/deeds';

  public static readonly AVAILABLE_DEEDS_V4_PATH = environment.API_URL + '/marketUnitsApi/v4/deeds';

  public static readonly MARKET_UNIT_ATTRIBUTE_OPTIONS =
    environment.API_URL + '/marketUnitsApi//v5/market_units/attributes_options';

  public static readonly PUBLISHING_MANAGEMENTS_PATH =
    environment.API_URL + '/marketUnitsApi/v6/beneficiary/publish_managements';

  // Authentication V4
  public static readonly AUTH_API_URL = environment.API_URL + '/authApi/api/v4';

  public static readonly AUTH_API_V3_URL = environment.API_URL + '/authApi/api/v3';
  public static readonly SAML_AUTH_API_URL = environment.API_URL + '/authApi/saml';

  public static readonly LOGIN_URL = `${Endpoints.AUTH_API_URL}/beneficiary_session`;

  // recaptcha validation
  public static readonly CAPTCHA_URL = environment.API_URL + '/captchaApi';

  // banks url
  public static readonly BANKS_URL = `${Endpoints.MAIN_INTERMEDIARY_V3}/banks`;

  // eligibility service
  public static readonly ELIGIBILITY_SERVICE_URL = environment.API_URL + '/eligibilityEngineServiceApi';

  // Assets API
  public static readonly ASSETS_API_V1_URL = environment.API_URL + '/assestsIntermediaryApi/api/v1';

  // service packages
  public static readonly MAIN_INTERMEDIARY_API_V2_URL = environment.API_URL + '/mainIntermediaryApi/v2';

  // Auction service
  public static readonly AUCTION_SERVICE_URL = environment.API_URL + '/auctionServiceApi/api/v3';

  // Activity Collect path
  public static readonly ACTIVITY_COLLECT_PATH = environment.API_URL + '/analyticCollector/collect';

  // mega projects
  public static readonly MARKETPLACE_V2_MEGA_PROJECT_MARKETPLACE_PATH =
    environment.API_URL + '/marketplaceApi/search/v2/mega-projects';

  // metaverse service
  public static readonly METAVERSE_SERVICE_URL = environment.API_URL + '/metaverseApi/api/v1';
  public static readonly MISC_PRODUCT_API_URL = environment.API_URL + '/miscProductsApi/api';
  public static readonly COLLECTOR_PATH = environment.API_URL + '/analyticCollector';
  public static readonly ADVERTISEMENT_API = environment.API_URL + '/advertisementApi/v1';

  // Favorite Projects Path
  public static readonly FAVORITES_URL =
    Endpoints.MAIN_INTERMEDIARY_V4 + '/beneficiary/project_available_subscriptions';

  // Global search path
  public static readonly GLOBAL_SEARCH_API_URL = environment.API_URL + '/marketplaceApi/search/v1/global';
  public static readonly GLOBAL_SEARCH_TRENDING_API_URL =
    environment.API_URL + `/cms_portal/api/v1/dx_service_categories/${environment.cmsSiteId}`;

  // market unit
  public static readonly MARKETPLACE_V2_MARKET_UNIT_MARKETPLACE_PATH =
    environment.API_URL + '/marketplaceApi/search/v2/market-places';

  // market unit
  public static readonly MARKETPLACE_V2_RENTAL_UNIT_MARKETPLACE_PATH =
    environment.API_URL + '/marketplaceApi/search/v2/rental-units';
}
