export enum MarketplacePurpose {
  BUY = 'buy',
  RENT = 'rent',
  AUCTION = 'auction',
  BUILD = 'build',
  FOR_YOUR_HOME = 'your_home',
  HOUSE_DESIGNS = 'house_designs',
  BROKER = 'broker',
  DEVELOPER = 'developer',
}
