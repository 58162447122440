import { environment } from '@environment/environment';
import { ButtonInterface } from '../shared/interfaces/button.interface';
import { EmployerSector, MappingMilitaryRank } from '@app/interfaces/employer-sector.interface';
import { DropdownInterface } from '@app/interfaces';
import { MarketplaceSplitviewSortEnum } from '@app/enums';

export class Constants {
  public static readonly EN = 'en';
  public static readonly AR = 'ar';

  public static readonly LTR = 'ltr';
  public static readonly RTL = 'rtl';

  public static readonly recaptchaV3SiteKey = environment.recaptchaV3SiteKey;
  public static readonly recaptchaSiteKey = environment.recaptchaSiteKey;

  public static readonly ESRI_API_KEY = environment.esriApiKey;
  public static readonly EMAIL_REGEX = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/;

  public static readonly DEFAULT_LANGUAGE = Constants.AR;
  public static readonly DEFAULT_DIRECTION = Constants.RTL;
  public static readonly REVERSE_DIRECTION = Constants.LTR;

  public static readonly DEFAULT_SESSION_LENGTH = 3600;
  public static readonly PHONE_UI_PREFIX = '+966 5';
  public static readonly PHONE_VALUE_PREFIX = '+9665';
  public static readonly HIDDEN_PHONE_NUMBER_PREFIX = '+966XXX';

  public static readonly DOB_REGEX = /^([0-3]?\d\.{1})([01]?\d\.{1})([12]{1}\d{3}\.?)$/g;
  public static readonly LOCAL_STORAGE_LANGUAGE = 'language';

  public static readonly PAGE_SIZE = 20;

  public static readonly FORMAT_DATE = 'dd/MM/yyyy';
  public static readonly FORMAT_DATETIME = 'DD/MM/yyyy, hh:mm';

  // financial info constants
  public static readonly ADDITIONAL_INCOME_MAX_VALUE = 100000;
  public static readonly PERSONAL_SAVING_MAX_VALUE = 1000000;
  public static readonly MONTHLY_OBLIGATION_MAX_VALUE = 45000;
  public static readonly MONTH_OF_DURATION_MAX_VALUE = 360;
  public static readonly DOWN_PAYMENT_MAX_VALUE = 1000000;
  public static readonly VIEWED_MARKETPLACE_ONBOARDING = 'viewed_marketplace_onboarding';
  public static readonly CHANGE_MOBILE_ROUTE = '/authentication/change-phone-number';

  public static readonly MINIMUM_PURCHASING_POWER_PERCENTAGE = 0.9;

  public static readonly VAT_RATE_DEFAULT = 0.15;

  public static readonly DEFAULT_BUTTON_OPTIONS: ButtonInterface = {
    btnType: 'button',
    leftIconClass: [],
    rightIconClass: [],
    btnState: 'active',
    btnClasses: ['btn', 'btn-primary', 'text-white'],
    btnLabel: '',
    hideLabelOnMobile: false,
  };

  public static readonly SEARCH_CATEGORIES: any = {
    cities: 'CITY',
    project_types: 'PROJECT_TYPE',
    projects: 'PROJECT',
    regions: 'REGION',
    unit_types: 'UNIT_TYPE',
  };

  public static readonly DEFAULT_NUMBER_OF_IMAGES_IN_CARD = 5;

  public static readonly CITIES_CACHE_VERSION = 'v4';

  public static readonly DISTRICTS_CACHE_VERSION = 'v4';

  public static readonly CACHE_KSA_CITIES = 'cities_by_region_version';

  public static readonly REGIONS = [
    {
      label: 'REGION.RIYADH',
      value: '1',
    },
    {
      label: 'REGION.MAKKAH',
      value: '2',
    },
    {
      label: 'REGION.MADINAH',
      value: '3',
    },
    {
      label: 'REGION.EASTERN_PROVINCE',
      value: '4',
    },
    {
      label: 'REGION.QASSIM',
      value: '5',
    },
    {
      label: 'REGION.HAIL',
      value: '6',
    },
    {
      label: 'REGION.ASIR',
      value: '7',
    },
    {
      label: 'REGION.JIZAN',
      value: '8',
    },
    {
      label: 'REGION.BAHAH',
      value: '9',
    },
    {
      label: 'REGION.NAJRAN',
      value: '10',
    },
    {
      label: 'REGION.TABUK',
      value: '11',
    },
    {
      label: 'REGION.NORTHERN_BORDERS',
      value: '12',
    },
    {
      label: 'REGION.JAWF',
      value: '13',
    },
  ];

  public static readonly DEFAULT_CENTER_LAT = 24.774265;
  public static readonly DEFAULT_CENTER_LON = 46.738586;
  public static readonly ZOOM_LEVEL_SWITCH_LAYER = 6;

  public static readonly WEBSOCKET_NAMESPACE = '/connect';

  public static readonly DELIMITER = '-';

  public static readonly WORK_TYPE_MILITARY = 'military';

  public static readonly ONLY_NUMBER_REX = /^[0-9]*\.?[0-9]*$/;

  public static readonly GOOGLE_LAT_REGEX = /(!3d[+-]?[0-9]*[.]?[0-9]+)/g;
  public static readonly GOOGLE_LNG_REGEX = /(!4d[+-]?[0-9]*[.]?[0-9]+)/g;

  public static readonly MIN_FOOT_PRINT_FOR_APARTMENT = 50;
  public static readonly MAX_FOOT_PRINT_FOR_APARTMENT = 1000;
  public static readonly MIN_FOOT_PRINT_FOR_OTHER_UNIT_TYPES = 150;
  public static readonly MAX_FOOT_PRINT_FOR_OTHER_UNIT_TYPES = 5000;
  public static readonly HIJI_YEAR = ['13', '14'];
  public static readonly GREO_YEAR = ['19', '20'];

  public static readonly QUERY_PARAM_MARKETPLACE_PURPOSE = 'marketplace_purpose';
  public static readonly MARKETPLACE_FILTER_BYPASS_KEY = [
    'view_mode',
    'sort',
    'marketplace_purpose',
    'lang',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'utm_source',
    'utm_medium',
    'utm_campaign',
  ];

  // TODO: remove
  public static readonly PAGES_ALLOW_SHOW_BANNER = [
    '/market-unit/',
    '/private-projects',
    '/readymade-projects',
    '/offplan-projects',
    '/land-projects',
    '/auction-projects',
    '/grouping-unit/',
    '/marketing-rental-unit/',
    '/units/',
    '/disclaimer',
    '/booking-summary',
    '/starting-offplan-projects',
  ];

  public static readonly CODE_PROJECTS = [
    '01-04-0002',
    '01-04-0001',
    '01-04-0017',
    '01-04-0009',
    '01-04-0011',
    '01-04-0010',
    '01-04-0014',
    '01-04-0008',
    '01-01-0011',
    '01-01-0003',
    '01-01-0004',
    '01-01-0010',
    '01-01-0002',
    '01-04-0013',
    '01-03-0007',
    '01-03-0011',
    '01-03-0008',
    '01-03-0006',
    '01-03-0009',
    '01-03-0010',
    '01-07-0001',
    '01-11-0004',
    '01-11-0001',
    '01-08-0001',
    '01-07-0005',
    '01-07-0002',
    '01-01-0019',
    '02-01-0029',
    '01-01-0020',
    '01-04-0020',
    '01-04-0019',
    '01-03-0004',
    '01-04-0018',
  ];

  public static readonly ONLY_TEXT_REGEX = '^(?! )[^<>{}"\'/|;:.,~!?@#$%^=&*\\]\\\\()\\[¿§«»ω⊙¤°℃℉€¥£¢¡®©_+]*(?<! )$';

  public static readonly EMPLOYER_SECTOR: EmployerSector[] = [
    { code: '1', value: 'GOVERNMENT_SECTOR' },
    { code: '2', value: 'PRIVATE_SECTOR' },
    { code: '3', value: 'MILITARY' },
    { code: '4', value: 'BUSINESS' },
    { code: '5', value: 'RETIREE_PUBLIC_PENSION_AGENCY' },
    { code: '6', value: 'RETIREE_GENERAL_ORGANIZATION_FOR_SOCIAL_INSURANCE' },
    { code: '7', value: 'NOT_WORKING' },
    { code: '8', value: 'SEMI_GOVERNMENTAL_SECTOR' },
  ];

  public static readonly BENEFICIARY_ASSET_DETAIL_EMPLOYER_SECTOR_MAPPING: EmployerSector[] = [
    { code: '1', value: 'CIVILIAN_GOVERNMENT_SECTOR' },
    { code: '2', value: 'CIVILIAN_PRIVATE_SECTOR' },
    { code: '3', value: 'MILITARY' },
    { code: '4', value: 'BUSINESS' },
    { code: '5', value: 'RETIREE_PUBLIC_PENSION_AGENCY' },
    { code: '6', value: 'RETIREE_GENERAL_ORGANIZATION_FOR_SOCIAL_INSURANCE' },
    { code: '7', value: 'NOT_WORKING' },
    { code: '8', value: 'SEMI_GOVERNMENTAL_SECTOR' },
  ];

  public static readonly PHONE_NUMBER_PREFIX: string = '+9665';

  public static readonly READYMADE: string = 'market_readymade_unit';
  public static readonly SELF_CONSTRUCTION: string = 'self_construction';
  public static readonly OFFPLAN: string = 'offplan';

  public static readonly OUTSIDE_PRODUCT_TYPES: DropdownInterface[] = [
    { label: 'MARKET_READYMADE_UNIT', value: this.READYMADE },
    { label: 'SELF_CONSTRUCTION', value: this.SELF_CONSTRUCTION },
  ];

  public static readonly OUTSIDE_READYMADE_UNIT_TYPES: DropdownInterface[] = [
    { label: 'VILLA', value: 'villa' },
    { label: 'APARTMENT', value: 'apartment' },
    { label: 'DUPLEX', value: 'duplex' },
    { label: 'TOWNHOUSE', value: 'townhouse' },
    { label: 'FLOOR_THROUGH', value: 'floor' },
  ];

  public static readonly OUTSIDE_SELF_CONSTRUCTION_UNIT_TYPES: DropdownInterface[] = [
    { label: 'LAND', value: 'land' },
    { label: 'HOME_INCOMPLETE', value: 'home_incomplete' },
  ];

  public static readonly MILITARY_RANKS: string[] = [
    'PRIVATE',
    'FIRST_PRIVATE',
    'CORPORAL',
    'LANCE_SERGEANT',
    'SERGEANT',
    'STAFF_SERGEANT',
    'CHIEF_OF_GENERAL_STAFF',
    'LIEUTENANT',
    'LIEUTENANT_PILOT',
    'FIRST_LIEUTENANT',
    'FIRST_LIEUTENANT_PILOT',
    'CAPTIN',
    'CAPTIN_PILOT',
    'MAJOR',
    'MAJOR_PILOT',
    'LIEUTENANT_COLONEL',
    'LIEUTENANT_COLONEL_PILOT',
    'COLONEL',
    'COLONEL_PILOT',
    'BRIGADIER',
    'BRIGADIER_PILOT',
    'GENERAL',
    'GENERAL_PILOT',
  ];

  public static readonly BENEFICIARY_MILITARY_RANK: MappingMilitaryRank[] = [
    { originalValue: 'PRIVATE', mappingValue: 'PERSONNEL_SOLDIERS' },
    { originalValue: 'FIRST_PRIVATE', mappingValue: 'PERSONNEL_FIRST_SOLDIER' },
    { originalValue: 'CORPORAL', mappingValue: 'INDIVIDUALS_CORPORAL' },
    { originalValue: 'LANCE_SERGEANT', mappingValue: 'PERSONNEL_SERGEANT_AGENT' },
    { originalValue: 'SERGEANT', mappingValue: 'INDIVIDUALS_SERGEANT' },
    { originalValue: 'STAFF_SERGEANT', mappingValue: 'INDIVIDUALS_FIRST_SERGEANT' },
    { originalValue: 'CHIEF_OF_GENERAL_STAFF', mappingValue: 'INDIVIDUALS_CHIEF_OF_STAFF' },
    { originalValue: 'LIEUTENANT', mappingValue: 'UNMANNED_LIEUTENANT' },
    { originalValue: 'LIEUTENANT_PILOT', mappingValue: 'PILOT_LIEUTENANT_OFFICERS' },
    { originalValue: 'FIRST_LIEUTENANT', mappingValue: 'UNMANNED_FIRST_TEAM_OFFICERS' },
    { originalValue: 'FIRST_LIEUTENANT_PILOT', mappingValue: 'PILOT_FIRST_LIEUTENANT_OFFICERS' },
    { originalValue: 'CAPTAIN', mappingValue: 'UNMANNED_CAPTAIN_OFFICERS' },
    { originalValue: 'CAPTAIN_PILOT', mappingValue: 'PILOT_CAPTAIN_OFFICERS' },
    { originalValue: 'MAJOR', mappingValue: 'UNMANNED_TEAM_OFFICERS' },
    { originalValue: 'MAJOR_PILOT', mappingValue: 'PILOT_TEAM_OFFICERS' },
    { originalValue: 'LIEUTENANT_COLONEL', mappingValue: 'UNMANNED_LIEUTENANT_COLONEL_OFFICERS' },
    { originalValue: 'LIEUTENANT_COLONEL_PILOT', mappingValue: 'PILOT_LIEUTENANT_COLONEL_OFFICERS' },
    { originalValue: 'COLONEL', mappingValue: 'UNMANNED_COLONEL_OFFICERS' },
    { originalValue: 'COLONEL_PILOT', mappingValue: 'PILOT_COLONEL_OFFICERS' },
    { originalValue: 'BRIGADIER', mappingValue: 'UNMANNED_BRIGADIER_OFFICERS' },
    { originalValue: 'BRIGADIER_PILOT', mappingValue: 'PILOT_BRIGADIER_OFFICERS' },
    { originalValue: 'GENERAL', mappingValue: 'UNMANNED_BRIGADIER_GENERAL_OFFICERS' },
    { originalValue: 'GENERAL_PILOT', mappingValue: 'PILOT_BRIGADIER_GENERAL_OFFICERS' },
  ];

  public static readonly PREFERRED_UNIT_TYPES: DropdownInterface[] = [
    { label: 'VILLA', value: 'villa' },
    { label: 'APARTMENT', value: 'apartment' },
    { label: 'DUPLEX', value: 'duplex' },
    { label: 'TOWNHOUSE', value: 'townhouse' },
    { label: 'FLOOR_THROUGH', value: 'floor_through' },
    { label: 'LAND', value: 'land' },
  ];

  public static readonly PQ_REQUEST_STATUSES = {
    DRAFT: 'draft',
    UNDER_CONTRACTOR_REVIEW: 'under_contractor_review',
    REJECTED_BY_CONTRACTOR: 'rejected_by_contractor',
    PRICE_QUOTATION_UNDER_PROCESS: 'price_quotation_under_process',
    REJECTED_BY_BENEFICIARY: 'rejected_by_beneficiary',
    UNDER_BENEFICIARY_REVIEW: 'under_beneficiary_review',
    EXPIRED: 'expired',
    ACCEPTED_BY_BENEFICIARY: 'accepted_by_beneficiary',
    PENDING_FOR_CONTRACT: 'pending_for_contract',
    CONTRACTED: 'contracted',
  };

  public static readonly LANDING_PAGE = 'landing_page';

  // Convert zl to scale: https://developers.arcgis.com/documentation/mapping-apis-and-services/reference/zoom-levels-and-scale/#zoom-level-to-scale-converter
  public static readonly ZOOM_LEVEL_GEOHASH_SCALE: any = {
    '19': 6,
    '18': 6,
    '17': 6,
    '16': 5,
    '15': 5,
    '14': 5,
    '13': 5,
    '12': 5,
    '11': 3,
    '10': 3,
    '9': 3,
    '8': 3,
    '7': 3,
    '6': 2,
    '5': 2,
    '4': 2,
    '3': 2,
    '2': 2,
    '1': 2,
    '0': 2,
  };

  // // use below for campaign
  public static readonly ZOOM_LEVEL_GEOHASH_SCALE_FOR_CAMPAIGN: any = {
    '19': 5,
    '18': 5,
    '17': 5,
    '16': 4,
    '15': 4,
    '14': 4,
    '13': 4,
    '12': 4,
    '11': 2,
    '10': 2,
    '9': 2,
    '8': 2,
    '7': 2,
    '6': 1,
    '5': 1,
    '4': 1,
    '3': 1,
    '2': 1,
    '1': 1,
    '0': 1,
  };

  public static readonly SERVICE_YOUR_OWN_DESIGN = 'service_your_own_design';
  public static readonly ERROR = 'error';
  public static readonly MARKETPLACE_DAY_SORT = [
    {
      label: 'MARKETPLACE.SPLIT_VIEW.SORT.MOST_POPULAR',
      value: MarketplaceSplitviewSortEnum.MOST_POPULAR,
    },
    {
      label: 'MARKETPLACE.SPLIT_VIEW.SORT.NEWEST_FIRST',
      value: MarketplaceSplitviewSortEnum.NEWEST_FIRST,
    },
    {
      label: 'MARKETPLACE.SPLIT_VIEW.SORT.OLDEST_FIRST',
      value: MarketplaceSplitviewSortEnum.OLDEST_FIRST,
    },
    {
      label: 'MARKETPLACE.SPLIT_VIEW.SORT.PRICE_HIGH_TO_LOW',
      value: MarketplaceSplitviewSortEnum.PRICE_HIGH_TO_LOW,
    },
    {
      label: 'MARKETPLACE.SPLIT_VIEW.SORT.PRICE_LOW_TO_HIGH',
      value: MarketplaceSplitviewSortEnum.PRICE_LOW_TO_HIGH,
    },
  ];

  public static readonly FETCH_FRC_RETRY_TIMES_MAXIMUM = 3;
  public static readonly FETCH_FRC_RETRY_DURATION = 30; // seconds
  public static readonly METAVERSE_URL = 'https://www.metaverse.sakani.sa';

  public static readonly ALPHANUMERIC_HYPHEN_UNDERSCORE_REGEX = /^[\u0621-\u064Aa-zA-Z0-9_\s-]*$/;
  public static readonly TRIMMED_REGEX = /^(\s+\S+\s*)*(?!\s).*$/;

  public static readonly METAVERSE_SOURCE = {
    SAKANI: 'sakani',
    EXTERNAL_ENTITY: 'external_entity',
  };
  public static readonly LOCAL_STORAGE_CHANGE_PW_BLOCKED_UNTIL = 'change_pw_blocked_until';

  public static readonly AUTH_EXCHANGE_REDIRECT_KEY = {
    PROJECT_PREVIEW_MAP: 'project_preview_map',
    PROJECT_PREVIEW: 'project_preview',
    OFFPLAN_PROJECT_DETAIL: 'offplan_project_detail',
    SIGN_SALES_CONTRACT: 'sign_sales_contract',
    UPDATE_BANK_ACCOUNT: 'update_bank_account',
  };

  public static readonly NHC_FILTER_KEY = 'nhc';
  public static readonly EXTERNAL = 'external';
  public static readonly USER_INFO_COOKIE_KEY = 'user_info';

  public static readonly QUERY_PARAM_UPDATE_BANK_ACCOUNT = 'update-bank-account';

  public static readonly YOUR_OWN_DESIGN_LAND_AREA_MIN = 175;
  public static readonly YOUR_OWN_DESIGN_LAND_AREA_MAX = 500;
  public static readonly NO_SPECIAL_CHARACTER_REGEX = /^(?!.*[!@#$%^&*()\-_=+\\|[\]{};:\/?.><~`]).*$/;
  public static readonly NO_SPECIAL_CHARACTER_BUT_SLASH_REGEX = /^(?!.*[!@#$%^&*()\-_=+\\|[\]{};:?.><~`]).*$/;
  public static readonly AUCTION_FEE_VAT_VALUE = 0.15; // 15%

  public static readonly REAL_ESTATE_TRANSACTION_TAX_RATE = 0.05; // 5%
  public static readonly VALUE_OF_THE_QUEST_RATE = 0.025; // 2.5%
  public static readonly VAT_ON_THE_QUEST_RATE = 0.15; // 15%
  public static readonly ALJAZIRA_BANK_EMPLOYER_NAME_LABEL_MAPPING: any = {
    'General Intelligence': {
      ar: 'الشؤون الإدارية والمالية',
      en: 'Administrative and Financial Affairs',
    },
    'General Intelligence MIL': {
      ar: 'الشؤون الإدارية والمالية',
      en: 'Administrative and Financial Affairs',
    },
  };

  public static readonly DOWNLOAD_SAKANI_APP_PLAYSTORE_URL =
    'https://play.google.com/store/apps/details?id=sa.housing.sakani&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
  public static readonly DOWNLOAD_SAKANI_APP_APPSTORE_URL = 'https://apps.apple.com/us/app/sakani/id1439423333';
  public static readonly YOUTUBE_IFRAME_API_URL = 'https://www.youtube.com/iframe_api';
  public static readonly YOUTUBE_VIDEO_ID_REGEX = /(?:youtube\.com\/embed\/|youtu\.be\/)([^"&?\/\s]{11})/;
  public static readonly STANDALONE_MODULE = ['notifications', 'sidebar-account', 'search-center'];
  public static readonly ACTION_CARD = 'actionCard';
  public static readonly RECENT_SEARCH = 'recentSearch';
  public static readonly NAFATH_SERVICE_TYPE = 'sakani';
  public static readonly GLOBAL_SEARCH_SHOW_ALL_COUNT = 3;
}
