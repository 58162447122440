export enum Resource {
  PROJECTS = 'projects',
  MEGA_PROJECTS = 'mega_projects',
  MARKET_UNITS = 'market_units',
  GROUP_UNITS = 'group_units',
  MARKETING_RENTAL_UNITS = 'marketing_rental_units',
  FAKED_RESOURCE = 'faked_resource',
  UNIT_MODELS = 'unit_models',
  UNITS = 'units',
  MARKET_UNIT = 'market_unit',
  MARKETING_RENTAL_UNIT = 'marketing_rental_unit',
}
