import { removeDuplicateObj } from '@app/helpers';
import { City } from './city';
import { Project } from './project';

export class RegisterInterestMegaProject {
  relationships: any = {};

  constructor(
    public id?: string,
    public mega_project_id?: string,
    public email?: string,
    public project_codes?: string[],
    public extra_information?: {
      mega_project_name?: string;
    }
  ) {}
}

export class RegisterInterestProject {
  relationships: any = {};

  constructor(
    public id?: string,
    public project_code?: string,
    public extra_info?: {
      name?: string;
      email?: string;
    }
  ) {}
}

export class RegisterInterestMarketplaceDay {
  relationships: any = {};

  constructor(public id?: string, public beneficiary_national_id_number?: string) {}
}

export class RegisterInterestCentralize {
  relationships: any = {};

  constructor(
    public id?: string,
    public beneficiary_name?: string,
    public cities?: Pick<City, 'id' | 'name_en' | 'name_ar'>[],
    public email?: string,
    public mega_projects?: { id: string | number; name: string }[],
    public mobile_number?: string,
    public projects?: (Pick<Project, 'id' | 'code' | 'name'> & { platform: string })[]
  ) {}

  removeDuplicates() {
    if (this.cities) {
      this.cities = removeDuplicateObj(this.cities);
    }
    if (this.projects) {
      this.projects = removeDuplicateObj(this.projects, 'code');
    }
    if (this.mega_projects) {
      this.mega_projects = removeDuplicateObj(this.mega_projects);
    }
  }
}
