<div class="toast-container v5" appLayoutDirection>
  <ngb-toast
    *ngFor="let toast of toastService.toasts"
    [class]="toast.className"
    [autohide]="true"
    [delay]="toast.delay || 5000"
    (hidden)="remove(toast)"
    appLayoutDirection
  >
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
      <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    </ng-template>

    <ng-template #text>
      <div class="d-flex align-items-start justify-content-between">
        <div class="d-flex">
          <div class="svg-icon svg-icon--medium icon-message-alert solid m-end-3"></div>
          <span class="initial-font" [innerHtml]="toast.textOrTpl"></span>
        </div>

        <div class="m-start-3 btn-close d-block" (click)="remove(toast)" [tabindex]="-1"></div>
      </div>
    </ng-template>
  </ngb-toast>
</div>
