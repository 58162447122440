export class WaivingRequest {
  relationships = {};

  constructor(
    public id?: string,
    public project_name?: string,
    public unit_code?: string,
    public unit_area?: number,
    public assignor_name?: string,
    public assignor_national_id_number?: string,
    public assignee_name?: string,
    public assignee_national_id_number?: string,
    public relative_relation?: string,
    public request_date?: string,
    public booking_id?: string
  ) {}
}
