@if (isTranslationAvailable) {
<div [ngClass]="{ 'massive-container': isMarketplace }" class="sticky-top">
  <app-nhc-topbar></app-nhc-topbar>
</div>

<div appLayoutDirection>
  <ngx-loading-bar [color]="'#00aca9'" [includeSpinner]="false" appLayoutDirection></ngx-loading-bar>

  <app-toast-container></app-toast-container>

  <div class="place-maincontent">
    <div class="main-wrapper" [ngClass]="{ 'massive-container': isMarketplace }">
      @if (!pageError) {
      <router-outlet></router-outlet>
      } @else {
      <app-error-section [code]="pageError.code"></app-error-section>
      }
    </div>

    <app-nhc-footer *ngIf="!isMarketplace" [socialNetworks]="cmsMetadata?.social_networks"></app-nhc-footer>
  </div>
</div>
}

<div @fade *ngIf="loading.status === true || !isTranslationAvailable">
  <div class="loading-backdrop"></div>
  <div class="loading-pos">
    <div class="loading-container d-flex flex-column align-items-center align-content-center">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="text-center" *ngIf="loading.text" [ngClass]="loading.textWrapperClass ?? ''">
        <span [ngClass]="loading.textClass ?? ''">{{ loading.text | translate }}</span>
      </div>
    </div>
  </div>
</div>

<ng-template #cookieModalTpl>
  <div class="d-flex d-md-none align-items-center justify-content-center pt-3" appLayoutDirection>
    <div class="mobile-indicator" (click)="cookieModalRef?.close(false)" [tabindex]="-1"></div>
  </div>
  <div class="modal-body position-relative p-0 d-block d-md-flex align-items-md-center container-md" appLayoutDirection>
    <div class="d-flex flex-column gap-2 m-3 flex-md-grow-1">
      <span class="fw-bold">{{ 'COOKIE_MODAL.THIS_WEBSITE_USING_COOKIES' | translate }}</span>
      <span>{{ 'COOKIE_MODAL.DESCRIPTION' | translate }}</span>
    </div>
    <div class="d-none d-md-block w-50 mx-3">
      <div class="d-flex justify-content-end align-items-center gap-5">
        <button class="btn btn-primary" (click)="cookieModalRef?.close(true)">
          {{ 'COOKIE_MODAL.ACCEPT_ALL' | translate }}
        </button>
        <button class="btn btn-secondary" (click)="cookieModalRef?.close(false)">
          {{ 'COOKIE_MODAL.REJECT' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="modal-footer d-md-none p-5 d-flex flex-column gap-3 justify-content-start" appLayoutDirection>
    <button class="btn btn-primary flex-grow-1 flex-md-grow-0 w-100" (click)="cookieModalRef?.close(true)">
      {{ 'COOKIE_MODAL.ACCEPT_ALL' | translate }}
    </button>
    <button class="btn btn-secondary flex-grow-1 flex-md-grow-0 w-100" (click)="cookieModalRef?.close(false)">
      {{ 'COOKIE_MODAL.REJECT' | translate }}
    </button>
  </div>
</ng-template>
