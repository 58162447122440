export enum OfferStatus {
  // Original Lending Request Status
  INITIAL = 'initial',
  RECEIVED = 'received',
  SUBMITTED = 'submitted',
  CANCELLED = 'cancelled',
  SIGNED = 'signed',
  WAITING_FOR_APPROVAL = 'waiting_for_approval',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  CREATED = 'created',
  GENERATED = 'generated',
  // Status to handle Row in UI
  LOADING = 'loading',
  ERROR = 'error',
}

export enum LendingOnlineRequestTabStatus {
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
}
