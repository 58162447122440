import { Injectable } from '@angular/core';
import { SakaniSession } from '@app/models';
import { BookingSession } from '@app/models/booking-session';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, map, of, Subject, switchMap, takeUntil, timer } from 'rxjs';
import { BookingSessionService } from '../http/booking-session.service';
import { ProjectService } from '../http/project.service';
import { ModalComponent } from '@app/shared/components/modal/modal.component';
import { SakaniModalService } from '@app/base/services/ui/sakani-modal.service';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class BookingTokenService {
  currentBookingInfo?: BookingSession;
  public session: SakaniSession | undefined;
  public bookingTokenSubs: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private projectService: ProjectService,
    private bookingSessionService: BookingSessionService,
    private sakaniModalService: SakaniModalService
  ) {
    this.loadBookingSession();
  }

  setSession(bookingSession: BookingSession) {
    if (bookingSession == null && this.isExpired()) {
      return;
    }
    if (bookingSession == null) {
      this.clear();
      return;
    }
    localStorage.setItem('bookingSession', JSON.stringify(bookingSession));

    this.loadBookingSession();
  }

  setReservedUnit(unit: any) {
    if (!this.currentBookingInfo) {
      return;
    }
    this.currentBookingInfo.unit_id = unit.id;
    localStorage.setItem('bookingSession', JSON.stringify(this.currentBookingInfo));
  }

  currentProjectName(): string {
    return this.currentBookingInfo?.project_name!;
  }

  currentProjectId(): number {
    return this.currentBookingInfo?.project_id!;
  }

  currentUnitId(): number {
    return this.currentBookingInfo?.unit_id!;
  }

  expiredAt(): number {
    return this.currentBookingInfo?.expired_at!;
  }

  isExpired(): boolean {
    if (!this.currentBookingInfo) {
      return false;
    }

    if (!this.currentBookingInfo.expired_at) {
      return false;
    }
    const expiredAt = this.currentBookingInfo?.expired_at || 0;
    return Date.now() > expiredAt * 1000;
  }

  hasToken(): boolean {
    return this.currentBookingInfo?.booking_token && this.currentBookingInfo?.booking_token != '' ? true : false;
  }

  hasQueueingToken(): boolean {
    return this.currentBookingInfo?.queueing_token ? true : false;
  }

  activeFinancingGuaranteeJourney(): boolean {
    return this.currentBookingInfo?.active_financing_guarantee_journey ? true : false;
  }

  fetchFRCRetryTimes(): number {
    return this.currentBookingInfo?.fetch_frc_retry_times || 0;
  }

  hasBookingUnit(): boolean {
    return !this.currentBookingInfo?.unit_id ? false : true;
  }

  leaveQueue() {
    return this.bookingSessionService.leaveQueue().pipe(
      map((res: any) => {
        this.clear();
        return res;
      })
    );
  }

  clear() {
    if (this.currentBookingInfo) {
      localStorage.removeItem('bookingSession');
      this.currentBookingInfo = undefined;
      this.bookingTokenSubs.next(undefined);
    }
  }

  clearTrackingPage() {
    localStorage.removeItem('loadPage');
  }

  matchProject(projectId: number): boolean {
    return this.currentProjectId() === projectId;
  }

  public loadBookingSession(): void {
    const bookingSession: string | null = localStorage.getItem('bookingSession');

    try {
      if (bookingSession && bookingSession !== 'undefined') {
        this.currentBookingInfo = JSON.parse(bookingSession);
      } else {
        this.currentBookingInfo = undefined;
      }
    } catch {
      this.currentBookingInfo = undefined;
    }

    this.bookingTokenSubs.next(this.currentBookingInfo);
  }

  isNhcProject(): boolean {
    console.log('this.currentBookingInfo?.is_nhc_project', this.currentBookingInfo?.is_nhc_project);
    return this.currentBookingInfo?.is_nhc_project ?? false;
  }
}
