import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaudiCurrencySymbolPipe } from './saudi-currency-symbol.pipe';

@NgModule({
  declarations: [SaudiCurrencySymbolPipe],
  imports: [CommonModule],
  exports: [SaudiCurrencySymbolPipe],
})
export class SaudiCurrencySymbolModule {}
