import {
  Auction,
  JsonApi,
  MarketplaceCardTag,
  MarketplaceDataInterface,
  MarketplaceUnitModel,
  Project,
  ProjectUnit,
  QueryMegaProject,
  UnitStatistic,
  UploadObject,
} from '.';
import { AuctionType, RealEstateType } from '@app/enums';
import { City } from './city';
import { GroupUnit } from './group-unit';
import { LocationData } from './location-data';
import { MarketUnit } from './market-unit';
import { MarketingRentalUnit } from './marketing-rental-unit';
import { Region } from './region';
import { ImageThumbGalleryDataInterface } from '@app/shared/interfaces';
import { environment } from '@environment/environment';

export class Marketplace {
  relationships = {};

  public static readonly MarketplaceNameTypeMapper: any = {
    offplan_private_land: 'MARKETPLACE_NAME.UNIT_UNDER_CONSTRUCTION',
    offplan_moh_land: 'MARKETPLACE_NAME.UNIT_UNDER_CONSTRUCTION',
    advanced_self_construction: 'MARKETPLACE_NAME.UNIT_UNDER_CONSTRUCTION',
    readymade_private_land: 'MARKETPLACE_NAME.READY_MADE_UNITS',
    readymade_moh_land: 'MARKETPLACE_NAME.READY_MADE_UNITS',
    single_unit: 'MARKETPLACE_NAME.READY_MADE_UNITS',
    grouping_units: 'MARKETPLACE_NAME.READY_MADE_UNITS',
    lands_moh_land: 'MARKETPLACE_NAME.LANDS',
    single_land: 'MARKETPLACE_NAME.LANDS',
    lands_private_land: 'MARKETPLACE_NAME.LANDS',
    offplan_land_private_land: 'MARKETPLACE_NAME.LANDS',
  };

  public static readonly AuctionTypeMapper: any = {
    electronic: 'MARKETPLACE_NAME.AUCTION_TYPE.ELECTRONIC',
    hybrid: 'MARKETPLACE_NAME.AUCTION_TYPE.HYBRID',
  };

  constructor(
    public id?: number,
    public resource_id?: string,
    public resource_type?: string,
    public subsidizable?: string,
    public project_name?: string,
    public project_type?: string,
    public group_unit_type?: string,
    public available_units_count?: string | number,
    public available_units_for_auctions_count?: string | number,
    public units_available_soon?: boolean,
    public market_unit_status?: string,
    public developer_name?: string,
    public group_unit_id?: string,
    public under_construction_status?: string,
    public city_id?: string,
    public region_id?: string | number,
    public city?: City,
    public region?: Region | string,
    public location?: LocationData,
    public unit_types?: string[],
    public min_price?: number,
    public max_price?: number,
    public min_area?: number,
    public max_area?: number,
    public min_unit_age?: number,
    public max_unit_age?: number,
    public min_street_width?: number,
    public max_street_width?: number,
    public min_bathroom?: number,
    public max_bathroom?: number,
    public min_bed_room?: number,
    public max_bed_room?: number,
    public driver_room?: boolean,
    public pool?: boolean,
    public elevator?: boolean,
    public basement?: boolean,
    public main_building_face?: string,
    public publish_date?: string,
    public views_count?: number,
    public publish?: boolean,
    public last_published_media?: any,
    public media_images?: any[],
    public units_statistic_data?: UnitStatistic,
    public published_actor?: string,
    public annual_unit_price?: number,
    public price_is_negotiable?: boolean,
    public area?: number,
    public bathrooms?: number,
    public bedrooms?: number,
    public branding_name?: string,
    public district?: string,
    public established_date?: string,
    public neighborhood?: string,
    public published_by?: string,
    public source?: string,
    public status?: string,
    public unit_age?: number,
    public unit_type?: string,
    public region_text?: string,
    public city_text?: string,
    public latest_auction_unit?: Auction,
    public bookable?: boolean,
    public use_register_interest_flag?: boolean,
    public land_type?: string,
    public tags?: MarketplaceCardTag,
    public marketplace_price?: number,
    public starting_bid_price?: number,
    public project_id?: number,
    public is_favorite?: boolean,
    public type?: string,
    public nhc_related?: boolean,
    public auction_type?: AuctionType,
    public market_unit_request_class?: string,
    public market_unit_source?: string,
    public project_code?: string,
    public extra_info?: { is_land: boolean },
    public can_book?: boolean,
    public content?: string,
    public cover_image?: string,
    public title?: string,
    public auction_status?: string,
    public data?: MarketplaceDataInterface,
    public name?: string,
    public banner_image?: UploadObject,
    public city_obj?: City,
    public region_obj?: Region,
    public apartment_count?: string | number,
    public model?: string,
    public project?: Project
  ) {}

  get existingTags() {
    if (!this.tags) {
      return;
    }
    const keyTags = Object.entries(this.tags!);
    const existingTags = keyTags.filter((item) => item[1] === true);
    return existingTags;
  }

  toProject() {
    if (this.resource_type !== 'projects') {
      throw new Error('Can not convert to Project');
    }

    return JsonApi.parseJsonApi(Project, {
      id: this.resource_id,
      type: 'projects',
      attributes: {
        media: this.last_published_media,
        realtime_available_units_count: this.available_units_count,
        units_available_soon: this.units_available_soon,
        units_statistic_data: this.units_statistic_data,
        developer_name: this.developer_name,
        location: this.location,
        project_type: this.project_type,
        media_name: this.project_name,
        name: this.name,
        city_id: this.city_id,
        city_obj: this.city,
        region_obj: this.region,
        price_starting_at: this.min_price,
        marketplace_name: Marketplace.MarketplaceNameTypeMapper[this.project_type || ''],
        latest_auction_unit: this.latest_auction_unit,
        bookable: this.bookable,
        use_register_interest_flag: this.use_register_interest_flag,
        tags: this.existingTags,
        marketplace_price: this.marketplace_price,
        starting_bid_price: this.starting_bid_price,
        auction_type: Marketplace.AuctionTypeMapper[this.auction_type || ''],
        code: this.project_code,
        is_favorite: this.is_favorite,
        nhc_related: this.nhc_related,
      },
    });
  }

  toMegaProject() {
    if (this.resource_type !== 'mega_projects') {
      throw new Error('Can not convert to Mega Project');
    }

    return JsonApi.parseJsonApi(QueryMegaProject, {
      id: this.resource_id,
      type: 'mega_projects',
      attributes: {
        id: this.resource_id,
        content: this.content,
        cover_image: this.cover_image,
        title: this.title,
        city: this.city,
        region: this.region,
        total_units_count: this.available_units_count,
        total_area: this.area,
      },
    });
  }

  toUnit(item: Marketplace): ProjectUnit {
    return {
      ...item.data?.attributes,
      last_published_media: item.last_published_media,
      project_name: item.project_name,
    } as ProjectUnit;
  }

  toUnitModel() {
    if (this.resource_type !== 'unit_models') {
      throw new Error('Can not convert to UnitModel');
    }

    return JsonApi.parseJsonApi(MarketplaceUnitModel, {
      id: this.resource_id,
      type: 'unit_models',
      attributes: {
        project_id: this.project_id,
        media: this.last_published_media,
        realtime_available_units_count: this.available_units_count,
        units_available_soon: this.units_available_soon,
        units_statistic_data: this.units_statistic_data,
        developer_name: this.developer_name,
        location: this.location,
        project_type: this.project_type,
        media_name: this.project_name,
        city_id: this.city_id,
        city_obj: this.city,
        region_obj: this.region,
        price_starting_at: this.min_price,
        marketplace_name: Marketplace.MarketplaceNameTypeMapper[this.project_type || ''],
        latest_auction_unit: this.latest_auction_unit,
        bookable: this.bookable,
        use_register_interest_flag: this.use_register_interest_flag,
        tags: this.existingTags,
        marketplace_price: this.marketplace_price,
        starting_bid_price: this.starting_bid_price,
        code: this.project_code,
        nhc_related: this.nhc_related,
        is_favorite: this.is_favorite,
        subsidizable: this.subsidizable,
        model: this.model,
      },
    });
  }

  toMarketUnit() {
    const unit_type = this.unit_types?.length ? this.unit_types[0] : this.unit_type || null;
    const marketplace_name =
      unit_type === null
        ? ''
        : unit_type === RealEstateType.LAND
        ? Marketplace.MarketplaceNameTypeMapper['single_land']
        : Marketplace.MarketplaceNameTypeMapper['single_unit'];

    if (this.resource_type != 'market_units') {
      throw 'Can not convert to MarketUnit';
    }

    return JsonApi.parseJsonApi(MarketUnit, {
      id: this.resource_id,
      type: 'market_units',
      attributes: {
        location: this.location,
        unit_type: unit_type || this.unit_type,
        city_id: this.city_id,
        region_id: this.region_id,
        city_obj: this.city,
        region_obj: this.region,
        unit_price: this.min_price,
        unit_area: this.min_area,
        number_of_rooms: this.min_bed_room,
        number_of_bathrooms: this.min_bathroom,
        published_actor: this.published_actor,
        published_by: this.published_by,
        publish_date: this.publish_date,
        thumbnails: this.mediaImagesToThumbnails(),
        unit_age: this.min_unit_age || this.max_unit_age,
        marketplace_name: marketplace_name,
        district: this.district,
        min_bed_room: this.min_bed_room,
        max_bed_room: this.max_bed_room,
        min_bathroom: this.min_bathroom,
        max_bathroom: this.max_bathroom,
        land_type: this.land_type,
        tags: this.existingTags,
        marketplace_price: this.marketplace_price,
        from_unit_marketing_request: this.market_unit_source === 'rega_v2',
        project_code: this.project_code,
        is_favorite: this.is_favorite,
        nhc_related: this.nhc_related,
      },
    });
  }

  toGroupUnit() {
    if (this.resource_type !== 'group_units') {
      throw new Error('Can not convert to GroupUnit');
    }

    return JsonApi.parseJsonApi(GroupUnit, {
      id: this.resource_id,
      type: 'group_units',
      attributes: {
        location: this.location,
        unit_type: this.unit_types?.length ? this.unit_types[0] : null,
        city_id: this.city_id,
        region_id: this.region_id,
        city_obj: this.city,
        region_obj: this.region,
        unit_price: this.min_price,
        published_actor: this.published_actor,
        published_by: this.published_by,
        publish_date: this.publish_date,
        thumbnail_url: this.mediaImagesToThumbnails()?.resize_234x,
        units_statistic_data: this.units_statistic_data,
        group_unit_type: this.group_unit_type,
        marketplace_name: Marketplace.MarketplaceNameTypeMapper['grouping_units'],
        district: this.district,
        available_unit_types: this.unit_types,
        subsidizable: this.subsidizable,
        tags: this.existingTags,
        marketplace_price: this.marketplace_price,
        project_code: this.project_code,
        is_favorite: this.is_favorite,
        nhc_related: this.nhc_related,
        min_area: this.min_area,
        max_area: this.max_area,
      },
    });
  }

  toRentalUnit() {
    return JsonApi.parseJsonApi(MarketingRentalUnit, {
      id: this.resource_id,
      type: 'marketing_rental_units',
      attributes: {
        annual_unit_price: this.annual_unit_price,
        price_is_negotiable: this.price_is_negotiable,
        area: this.area,
        bathrooms: this.bathrooms,
        bedrooms: this.bedrooms,
        branding_name: this.branding_name,
        city_obj: this.city,
        city_id: this.city_id,
        location: this.location,
        region_obj: this.region,
        region_id: this.region_id,
        source: this.source,
        unit_type: this.unit_type,
        established_date: this.established_date,
        region_text: this.region_text,
        city_text: this.city_text,
        media_images: this.mediaImagesToThumbnails()?.resize_234x,
        marketplace_price: this.marketplace_price,
        nhc_related: this.nhc_related,
        is_favorite: this.is_favorite,
      },
    });
  }

  mediaImagesToThumbnails(): any {
    if (this.media_images && this.media_images?.length > 0) {
      const thumbObj = this.media_images
        .filter((item) => {
          return (item?.tags as any[])?.includes('banner');
        })
        .slice(-1)
        .pop();
      if (!thumbObj) {
        return undefined;
      }

      let thumbUrl = thumbObj.small_size_url || thumbObj.image_url;

      return {
        resize_234x: (thumbUrl as string).replace('original', 'resize_preset_234'),
      };
    }
  }

  getDetailLink(megaProject: QueryMegaProject): string {
    return `${environment.nhcPath}/mega-project/${megaProject?.id}`;
  }

  get imageThumbsGallery(): ImageThumbGalleryDataInterface[] {
    const rawGallery = this.last_published_media?.gallery || [];
    const video: ImageThumbGalleryDataInterface = {
      sourceUrl: this.last_published_media?.video?.attributes?.url,
      thumbUrl: this.last_published_media?.video?.attributes?.thumbnail_url,
      originUrl: this.last_published_media?.video?.attributes?.url,
      type: 'video',
    };

    const banner: ImageThumbGalleryDataInterface = {
      sourceUrl: this.last_published_media?.banner?.attributes?.url,
      thumbUrl: this.last_published_media?.banner?.attributes?.thumbnail_url,
      originUrl: this.last_published_media?.banner?.attributes?.url,
    };

    // const master_plan: ImageThumbGalleryDataInterface = {
    //   sourceUrl: this.media?.master_plan?.attributes?.url,
    //   thumbUrl: this.media?.master_plan?.attributes?.thumbnail_url,
    //   originUrl: this.media?.master_plan?.attributes?.url,
    // };

    const gallery = rawGallery?.map((g: any) => {
      const data: ImageThumbGalleryDataInterface = {
        sourceUrl: g?.attributes?.url,
        thumbUrl: g?.attributes?.thumbnail_url,
        originUrl: g?.attributes?.url,
      };
      return data;
    });
    let projectImages: ImageThumbGalleryDataInterface[] = [];
    banner.sourceUrl ? projectImages.push(banner) : '';
    video.sourceUrl ? projectImages.push(video) : '';
    // master_plan.sourceUrl ? projectImages.push(master_plan) : '';

    projectImages = [...projectImages, ...gallery];
    return projectImages;
  }
}
