import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutDirectionModule } from '@app/shared/directives';
import { JoodEskanBannerComponent } from './jood-eskan-banner.component';

@NgModule({
  declarations: [JoodEskanBannerComponent],
  imports: [CommonModule, TranslateModule, LayoutDirectionModule],
  exports: [JoodEskanBannerComponent],
})
export class JoodAleskanBannerModule {}
