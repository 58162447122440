import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchHighlightTextPipe } from './search-highlight-text.pipe';

@NgModule({
  declarations: [SearchHighlightTextPipe],
  imports: [CommonModule],
  exports: [SearchHighlightTextPipe],
})
export class SearchHighlightTextModule {}
