import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../services/ui/toast.service';
import { SakaniSessionService } from '../services/ui/sakani-session.service';
import { LoadingEventService } from '../services/ui/loading-events.service';
import { CaptchaDialogService } from '../services/ui/captcha-dialog.service';

// Ref link: https://angular.io/api/common/http/HttpStatusCode
@Injectable()
export class SakaniErrorHandler implements ErrorHandler {
  constructor(private injector: Injector, private zone: NgZone) {}

  handleError(error: Error | HttpErrorResponse): void {
    if (error instanceof HttpErrorResponse) {
      // Server Error
      let msg = '';
      if (error.status === HttpStatusCode.NotFound) {
        msg = this.translateService.instant('ERRORS.TITLE_404');
      } else if (error.status === HttpStatusCode.PreconditionFailed) {
        this.captchaDialogService.showCaptchaDialog(
          'remove_blacklist',
          this.sessionService.currentUser?.national_id_number
        );
      } else if (
        [
          HttpStatusCode.UnprocessableEntity,
          HttpStatusCode.Forbidden,
          HttpStatusCode.BadGateway,
          HttpStatusCode.BadRequest,
        ].indexOf(error.status) > -1
      ) {
        const resErr = error?.error?.errors;
        console.log(resErr);
        if (resErr?.length > 0) {
          msg = this.translateService.instant(`ERRORS.${resErr[0]?.code}`.toUpperCase());
        } else {
          msg = this.translateService.instant('ERRORS.TITLE_403');
        }
      } else {
        msg = this.translateService.instant('ERRORS.TITLE_5XX');
      }

      // display error to end user
      this.zone.run(() => {
        this.toastService.showError(msg);
      });
    } else {
      // Critical client error...
      console.error(error);
      const msg = this.translateService.instant('ERRORS.APPLICATION_ERROR');
      this.toastService.showError(msg);
    }
    // remove loading state
    this.loadingEventService.complete();
  }

  private get toastService(): ToastService {
    return this.injector.get(ToastService);
  }

  private get translateService(): TranslateService {
    return this.injector.get(TranslateService);
  }

  private get sessionService(): SakaniSessionService {
    return this.injector.get(SakaniSessionService);
  }

  private get router(): Router {
    return this.injector.get(Router);
  }

  private get loadingEventService(): LoadingEventService {
    return this.injector.get(LoadingEventService);
  }

  private get captchaDialogService(): CaptchaDialogService {
    return this.injector.get(CaptchaDialogService);
  }
}
